// import { format } from "date-fns";
import { quillEditor } from "@shared/lib/vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import Add from "@/assets/icons/email-admin/icon-add-white.svg";
import DropdownIcon from "@/assets/icons/email-admin/icon-dropdown.svg";
import UpIcon from "@/assets/icons/email-admin/icon-up-white.svg";
import DownIcon from "@/assets/icons/email-admin/icon-down.svg";
import CloseIcon from "@/assets/icons/email-admin/icon-crossbt.svg";
import TrashRed from "@/assets/icons/email-admin/icon-trash-red.svg";
import EditIcon from "@/assets/icons/email-admin/icon-edit.svg";
import ReplaceIcon from "@/assets/icons/email-admin/icon-replace.svg";
import MenuList from "@/components/dropdown";
import EmailDays from "../../pages/email-admin/email-days";
import MenuButton from "@/components/menu-button";
import axios from "@/axios";
// import EmailTempModal from "@/components/emailtemplatemodal";
import emailTempModal from "@/components/emailtemplatemodal";
import BrowseDir from "@/components/browse-dir";
import { cloneDeep } from "lodash";
import Checkbox from "@/components/checkbox"
// import { checkPermission } from "@shared/utils/functions"
import Loader from "@shared/loader"


export default {
  name: "sequence",
  components: {
    MenuList,
    "trash-red": TrashRed,
    "edit-icon": EditIcon,
    "replace-icon": ReplaceIcon,
    Add,
    DropdownIcon,
    UpIcon,
    CloseIcon,
    DownIcon,
    EmailDays,
    MenuButton,
    BrowseDir,
    emailTempModal,
    Checkbox,
    Loader,
    quillEditor
  },
  data() {
    return {
      templates: [],
      templateOptions: false,
      templateOptionsIndex: null,
      sequenceTemplates: [],
      isTemplateModalOpen: false,
      isCreateSequenceModal: false,
      errorMessage: '',
      directoryToastMessage: null,
      stopSequence: false,
      showError: false,
      editorOptions: {
        placeholder: "Insert text here",
        readOnly: true,
        theme: "snow",
        modules: {
            toolbar: {
                container: [
                    [{ 'font': [] }, { size: ["small", false, "large", "huge"] }, "bold", "italic", "underline", { 'color': [] }, { 'list': 'ordered' }, { 'align': [] }, 'clean'],                       // text direction
                ],
            },
        },
      },
    };
  },
  props: {
    handlePlusClick: {
      type: Function,
      default: () => { },
    },
    handleCreateFolder: Function,
    fetchSequenceList: Function,
    open: Boolean,
    id: {
      type: String,
      default: null,
    },
    templatesData: Array,
    isSequenceEditable: Boolean,
    isSequenceModalOpen:Boolean,
    populateData: Object,
    dirList: Array,
    path: Array,
    isEmailSend: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    emailTo: {
      type: String,
      default: ""
    },
    isShowStopSequence: {
      type: Boolean,
      default: false
    },
    createMode: {
      type: Boolean,
      default:  false
    },
    isSendDisabled: {
      type: Boolean,
    },
    isSearchModal: Boolean,
    newModal:Boolean,
    isSequenceLoading: Boolean,
    currSeqAllTemplates: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    open: function (newVal) {
      if (newVal) {
        this.showModal();
      } else {
        this.hideModal();
      }
    },
    templatesData: function (newVal) {
      this.sequenceTemplates = newVal?.map((x,i) => ({
        ...x, 
        day: x.day || newVal[i-1]?.day || 1,
      }));
      if(this.isEmailSend){
        for(let item in this.currSeqAllTemplates){
          this.sequenceTemplates[item].body = this.currSeqAllTemplates[item];
        }
      }
    },
  },
  methods: {
    // checkPermission,
    async saveTemplate(formData) {
      formData.templateName = formData.templateName.trim();
      this.directoryToastMessage = null;
      if (formData.templateName) {
        if (formData.templateName.includes("/")) {
          this.directoryToastMessage = {
            type: 'error',
            message: `File name can't have / `,
          };
          return ;
        }
        try {
          if (formData.id) {
            formData?.sequences.sort((a, b) => {
              if (a.id && !b.id) {
                return -1; 
              } else if (!a.id && b.id) {
                return 1;
              } else {
                return 0;
              }
            });
            formData?.sequences.forEach((element ,i) => {
              if(element.day == 0){
                element.day=1
              }
              element.number = i + 1
            })
            await axios.post("email-sequence/update", {
              //tenant id or candidate
              id: formData.id,
              name: formData.templateName,
              path: `${formData?.path}/${formData.templateName}`.replace("//", "/"),
              description: formData.description,
              sequences: formData.sequences,
            });
            this.hideModal();
            this.$toast.success("Sequence updated successfully!");
            this.$emit("clearSequenceDetails");
          } 
          else {
            let sequences=[]
            sequences = this.sequenceTemplates.map((x, i) => ({
              number: i + 1,
              template_id: x.id,
              day: x.day,
            }));
            try {
              await axios.post("email-sequence/create", {
                external_id: this.$store.getters.getTenantId,
                path: `${formData?.path}/${formData.templateName}`.replace("//", "/"),
                name: formData.templateName,
                description: formData.description,
                resource_type: "resource",
                sequences: sequences,
              });
              this.$toast.success("Sequence created successfully!");
            } catch (error) {
              console.log(error, "unable to create sequence");
              this.$toast.error(error?.response?.data?.detail || "Something went wrong. Pleases try again");
            }
            this.hideModal();
            this.$emit("clearSequenceDetails");
          }
        } catch (error) {
            this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
        }
        await this.fetchSequenceList();
        this.toggleModal();
      } else {
        this.errorMessage = 'Please enter a sequence name';
      }
    },
    showModal() {
      this.$refs.sequenceModal.showModal();
    },
    hideModal() {
      this.errorMessage = '';
      this.directoryToastMessage = null;
      this.stopSequence=false;
      this.isTemplateModalOpen = false;
      this.showError = false;
      this.$emit("update:open", false);
      this.$refs.sequenceModal.close();
    },
    popupbtn(i) {
      if (i === this.templateOptionsIndex) {
        this.templateOptions = false;
        this.templateOptionsIndex = null;
      } else {
        this.templateOptions = true;
        this.templateOptionsIndex = i;
      }
    },
    handleOutsideClick() {
      this.popupbtn(this.templateOptionsIndex) ;
    },
    handleClick(item) {
      if (item.key === 'remove') {
        const sequenceTemplates = cloneDeep(this.sequenceTemplates);
        const allSeq = this.sequenceTemplates;
        sequenceTemplates.splice(this.templateOptionsIndex, 1);
        allSeq.splice(this.templateOptionsIndex, 1);
        this.$emit("updateSequenceData", sequenceTemplates);
        this.$emit("updateAllSequences", allSeq);
      } else if (item.key === 'replace') {
        this.handlePlusClick(this.templateOptionsIndex);
      }
    },
    updateSequenceData(days, index) {
      const allSeq = this.populateData.sequences;
      this.sequenceTemplates[index] = { ...this.sequenceTemplates?.[index], day: days };
      allSeq[index] = { ...allSeq?.[index], day: days };
      // for (let i = index+1; i < this.sequenceTemplates?.length ; i++) {
      //   if (this.sequenceTemplates[i].day < days)
      //     this.sequenceTemplates[i].day = days;
      //   if (allSeq[i].day < days)
      //     allSeq[i].day = days;
      // }
      this.$emit("updateSequenceData", this.sequenceTemplates);
      this.$emit("updateAllSequences", allSeq);
    },
    updateOnChangeSequenceData(days,index) {
      this.sequenceTemplates[index] = {...this.sequenceTemplates?.[index], day: days};
      this.$emit("updateOnChaneSequenceData", this.sequenceTemplates);
    },
    async openTemplate() {
      this.isSendDisabled = true;
      if(this.isEmailSend){
        this.$emit("on-send-email", { 
          stop_on_form_submission: this.stopSequence,
          email_to: this.emailTo
        })
        this.$emit('sequenceTempAfterSendingEmail',this.sequenceTemplates)
        return; 
      }
      if(this.sequenceTemplates.length === 0 || this.sequenceTemplates.length === 1){
        this.isCreateSequenceModal = true;
        this.isTemplateModalOpen = true;
        if(this.newModal === true){
          this.populateData =  {
            id: "",
            content: "",
            templateName: "",
            description: "",
            templateSubject: "",
            path: "/",
            placeholder_metadata: []
          };
        }
      }
      else{
        let flag = false;
        for(let i=1; i<this.sequenceTemplates.length; i++){
          if(this.sequenceTemplates[i].day<=0)
            flag = true;
        }
        if(flag === false){
          this.showError = false;
          this.isCreateSequenceModal = true;
          this.isTemplateModalOpen = true;
        }
        if(flag === true){
          this.showError = true;
          this.isCreateSequenceModal = false;
          this.isTemplateModalOpen = false;
        }
      }
    },
    toggleModal() {
      this.errorMessage = '';
      this.isTemplateModalOpen = false;
      this.open.directoryToastMessage = null;
    },
    minDays(i) {
      return this.sequenceTemplates[i-1]?.day || 0;
    },
  },
  computed: {
    icons() {
      return [
        { icon: "replace-icon", label: "Replace", key: 'replace' },
        { icon: "trash-red", label: "Remove", key: 'remove' },
      ];
    },
    checkSequencePermissions() {
      // if (this.createMode) return this.checkPermission('sequence.create')
      // return this.checkPermission('sequence.edit') 
      return true;
    }
  },
  async mounted() {
    if (!this.$store.getters.getTenantId) {
      await this.$store.dispatch("fetchTenantId");
    }
    this.$emit('isSearchModalFunc',this.isSearchModal);
  },
};
