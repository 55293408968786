import Search from "@/assets/icons/email-admin/icon-search.svg";
import Check_active from "@/assets/icons/email-admin/icon-check-active.svg";
import Check from "@/assets/icons/email-admin/icon-check.svg";
import FolderIcon from "@/assets/icons/email-admin/icon-folder.svg";
import ArrowIcon from "@/assets/icons/email-admin/icon-arrow.svg";
import TextTemplate from "@/assets/icons/email-admin/icon-text-temp.svg";
import GroupIcon from "@/assets/icons/email-admin/icon-group.svg";
import Empty from "@shared/empty"

import DownIcon from "@/assets/icons/email-admin/icon-down.svg";
import { cloneDeep } from "lodash";

export default {
  name: "BrowseDir",
  components: {
    Search,
    FolderIcon,
    Check,
    Empty,
    ArrowIcon,
    TextTemplate,
    DownIcon,
    GroupIcon,
    Check_active
  },

  props: {
    dirList: Array,
    handleCreateFolder: Function,
    showCreateFolder: {
      type: Boolean,
      default: true
    },
    clickedTemplate:Function,
    isRowClickable:{
      type: Boolean,
      default: false
    },
    directoryToastMessage: Object,
    successMessage: String,
    path: Array,
    open: Boolean,
    isModalOpen: {
      type: Boolean,
      default: false
    },
    sequence: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      folderPath: [],
      values: {},
      searchQuery: '',
      checkIcon:false,
    }
  },
  computed: {
    rows() {
      this.values = {};
      let result = [...this.dirList] || [];
      for (let i = 0; i < this.folderPath.length; i++) {
        let finalResult = result?.find(val => this?.folderPath?.[i]?.id == val?.id && this?.folderPath?.[i]?.name == val?.name);
        result = finalResult?.content || [];
      }
      console.log('this.dirList :>> ', result);
      return result;
    },
    filteredRows() {
      if (this.searchQuery) {
        return this?.rows?.filter((item) => {
          if(item?.name?.toLowerCase()?.includes((this.searchQuery.toLowerCase()))){
            if(item.type==='directory' || item.type!=='directory')
              return item?.name?.toLowerCase()?.includes(this.searchQuery.toLowerCase());
          }
          else{
            for(let i in item.content){
              if(item?.content[i].name?.toLowerCase()?.includes((this.searchQuery.toLowerCase())))
                return item?.content[i]?.name?.toLowerCase()?.includes(this.searchQuery.toLowerCase());
            }
          }
        });
      } else {
        return this.rows;
      }
    }
  },
  methods: {
    handlePath(data, index) {
      const { ...rest } = data;
      if (rest.type === "directory") {
        this.folderPath.push({ ...rest, index: index });
        if (this.folderPath?.length)
          this.$emit("onSelectPath", this.folderPath.slice(-1)[0].path);
      } else {
        if (this.isRowClickable) {
          this.clickedTemplate(data)
        }
      }
    },
    backPath() {
      this.folderPath.pop();
      if (this.folderPath?.length){
        this.$emit("onSelectPath", this.folderPath.slice(-1)[0].path);
      }else{
        this.folderPath.path='/'
        this.$emit("onSelectPath", this.folderPath.path);
      }
    },
    async createFolder() {
      const folderName = `${this.values.folder}`;
      this.checkIcon=true
      const path =
        this.folderPath.length > 0
          ? `${this.folderPath.slice(-1)[0].path}/${folderName}`
          : `/${folderName}`;
      const folderInfo = {
        external_id: `${this.$store.getters.getTenantId}`,
        name: this.values.folder,
        path: path,
        description: this.values.description,
        resource_type: "directory",
      };
      await this.handleCreateFolder(folderInfo);
      this.values={};
      this.$formulate.reset("createFolder");
      this.checkIcon=false
    },
    getBreadcrumbStyle(path) {
      let style;
      if (path.name.length > 10) {
        // const count = this.folderPath?.length < 3 ? this.folderPath?.length : 3;
        const count = 5;
        const totalWidth = document.getElementsByClassName("directory-breadcrumbs")?.[0]?.offsetWidth;
        const value = (totalWidth / count) - 10;
        style = `width: ${value}px`;
      }
      return style;
    },
  },
  async mounted() {
    if (!this.$store.getters.getTenantId) {
      await this.$store.dispatch("fetchTenantId");
    }
    this.folderPath = cloneDeep(this.path || []);
  },
  watch: {
    open: function (newVal) {
      if (newVal) {
        this.folderPath = cloneDeep(this.path);
      }
    },
    isModalOpen() {
      this.searchQuery = "";
      this.folderPath = [];
    }
  }
};
