import axios from "@/axios";
import Input from "@/components/input";
import CustomInput from "@/components/custom-input";
import TextTemplate from "@/assets/icons/email-admin/icon-text-temp.svg";
import DropdownIcon from "@/assets/icons/email-admin/icon-dropdown.svg";
import BoldIcon from "@/assets/icons/email-admin/icon-bold.svg";
import ItalicIcon from "@/assets/icons/email-admin/icon-italic.svg";
import UnderlineIcon from "@/assets/icons/email-admin/icon-underline.svg";
import StrikeThroughIcon from "@/assets/icons/email-admin/icon-strikethrough.svg";
import OtherIcon from "@/assets/icons/email-admin/icon-other.svg";
import ClipIcon from "@/assets/icons/email-admin/icon-clip.svg";
import CloseIcon from "@/assets/icons/email-admin/icon-crossbt.svg";
import DeleteIcon from "@/assets/icons/email-admin/icon-delete.svg";
import EditIcon from "@/assets/icons/ico-edit.svg";
import Button from "@/components/button/index.vue";
import BrowseDir from "@/components/browse-dir";
import MenuButton from "@/components/menu-button";
import FolderIcon from "../../assets/icons/email-admin/icon-folder.svg";
import Check from "../../assets/icons/email-admin/icon-check.svg";
import Search from "../../assets/icons/email-admin/icon-search.svg";
import ArrowIcon from "@/assets/icons/email-admin/icon-arrow.svg";
// import { checkPermission } from "@shared/utils/functions";

import EmailPlaceholder from "./placeholders";
import FormPlaceHolder from "./form-placeholder"
// import EmailMessageField from '@shared/components/fields/email-message.vue';

import { quillEditor, Quill } from "@shared/lib/vue-quill-editor";
import { ImageExtend } from "quill-image-extend-module";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import QuillBetterTable from "quill-better-table";
Quill.register("modules/ImageExtend", ImageExtend);
Quill.register(
  {
      "modules/better-table": QuillBetterTable,
  },
  true
);
var Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block)
import { uniq } from "lodash";
import ModalContent from "@shared/modal-content";
export default {
  name: "Emailtemplatemodal",
  components: {
    quillEditor,
    "neo-input": Input,
    "neo-button": Button,
    ClipIcon,
    CloseIcon,
    texttemplate: TextTemplate,
    CustomInput,
    EditIcon,
    DeleteIcon,
    DropdownIcon,
    BoldIcon,
    UnderlineIcon,
    ItalicIcon,
    OtherIcon,
    StrikeThroughIcon,
    BrowseDir,
    Check,
    Search,
    ArrowIcon,
    FolderIcon,
    MenuButton,
    EmailPlaceholder,
    FormPlaceHolder,
    // EmailMessageField,
    ModalContent,
  },
  data() {
    return {
      data: [
        { label: "Case Studies" },
        { label: "Customers" },
        { label: "Engaged" },
        { label: "Follow up_General" },
        { label: "Follow up_General" },
        { label: "Follow up_General" },
        { label: "Follow up_General" },
        { label: "Follow up_General" },
      ],
      editorOptions: {
        debug: "info",
        placeholder: "Insert text here",
        readOnly: true,
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              [{ size: ["small", false, "large", "huge"] }],
              ["bold", "italic", "underline", "strike"],
              ["clean"],
            ],
          },
          table: false, 
          'better-table': {
            operationMenu: {
              items: {
                  // unmergeCells: {
                  //   text: 'Another unmerge cells name'
                  // }
              },
              color: {
                  colors: ["#fff", "red", "#d5e1df", "#e3eaa7", " #b5e7a0", "#eea29a", "#80ced6", "#f7786b", "#f4e1d2"], // colors in operationMenu
                  text: "Background Colors", // subtitle
              },
            }
          },
          keyboard: {
            bindings: QuillBetterTable.keyboardBindings
          },
        },
      },
      formData: {
        id: "",
        content: "",
        templateName: "",
        description: "",
        templateSubject: "",
        path: "/",
        placeholder_metadata: []
      },
      tempId:null,
      sendoptions: false,
      directoryList: [],
    };
  },
  props: {
    open: Boolean,
    populateData: Object,
    newModal:Boolean,
    toggleModal: Function,
    dirList: Array,
    handleCreateFolder: Function,
    fetchTemplateList:Function,
    handleSave: Function,
    isCreateSequenceModal: {
      type: Boolean,
      default: false
    },
    editableSequence: {
      type: Boolean
    },
    errorMessage: String,
    directoryToastMessage: null, 
    path: Array,
    width: {
      type: String,
      default: '85%',
    },
    isButtonShown:{type: Boolean, default: false}
  },
  computed: {
    isPermission() {
      let is_created = (this.formData.id === '') || false
      if (is_created) return true
      else return true;
    }
  },
  async mounted() {
    this.tempId=this.formData?.id
    if (this.path?.length)
      this.handleSelectedPath(this.path.slice(-1)[0].path);
  },
  watch: {
    open: function (newVal) {
      if(this.newModal){
        this.formData.templateName=''
        this.formData.description=''
      }
      if (newVal) {
        if (this.path?.length)
          this.handleSelectedPath(this.path.slice(-1)[0].path);
        this.$modal.show('emailTemplateModal');
      } else {
        this.$modal.hide('emailTemplateModal');
      }
    },
    populateData: function (newVal) {
      if (newVal) {
        this.formData = newVal;
        let path = this.formData?.path || "";
        let arr = path?.split("/");
        if (arr?.length > 1)
          arr.pop();
        this.formData.path = arr.join("/") || "";
      }
    },
    dirList: function (newVal) {
      this.directoryList = newVal;
    },
  },
  methods: {
    // checkPermission,
    async setunlockTemplate() {
      let templateId=this.formData?.id
      if (templateId === null || templateId === "") {
        return;
      }
      if (this.formData?.sequences) {
         return
      }
      try {
        let payload = { lock_status: false }
        let url = `/email-template/locked/${templateId}`;
        await axios.post(url, payload);
        this.fetchTemplateList()
        return true;
    } catch (error) {
        this.$toast.error(error.response.data.detail || "Failed to lock this Template");
        return false; // Return false when there's an error
    }
    },
    replaceQuillPlaceholders() {
      let content = this.formData.content;
      let placeholders = content.match(/\{{(.*?)\}}/g);
      const tags = content.match(/<a(.*?)>/g);
      const quill = this.$refs.myQuillEditor?.quill;

      placeholders = uniq(placeholders?.filter((placeholder) => {
          return !tags?.some((tag) => tag.includes(placeholder))
      }));
      for (const index in placeholders) {
        if (quill) {
          const text = placeholders[index];
          let content = quill.getText();
          let sub_content = quill.getText();
          let start = 0;
          while (sub_content.indexOf(text) > -1) {
            start += sub_content.indexOf(text);
            const length = text?.length;
            this.formatText(quill, start, length);
            this.removeFormat(quill);
            start += length;
            sub_content = content.substr(start, content.length);
          } 
        }
      //   const phText = `<span class="placeholder">` + text + `</span>`;
      //   content = content.replaceAll(text, phText);
      }
    //   var editor = document.querySelector('.note-text-area .ql-editor');
    //   if (editor)
    //     editor.innerHTML = content;
    },
    insertTemplatePlaceholder(placeholder) {
      // const quill = this.$refs.myQuillEditor.$refs.editor.quill
      const quill = this.$refs.myQuillEditor.quill;
      if (quill) {
        var selection = quill.getSelection(true);
        const length = placeholder?.placeholder?.length;
        quill.insertText(selection.index, `{{${placeholder.placeholder}}}`);
        // this.formatText(quill, selection.index, length+4);
        // this.removeFormat(quill);
        quill.setSelection(selection.index+length+4);
      }
    },
    insertFormPlaceholder({name}) {
      // if (placeholder && placeholder.type )
      // const quill = this.$refs.myQuillEditor.$refs.editor.quill
      const quill = this.$refs?.myQuillEditor?.quill;
      if (quill) {
        var selection = quill.getSelection(true);
        const length = name?.length;
        quill.insertText(selection.index, `{{${name}}}`);
        // this.formatText(quill, selection.index, length+4);
        // this.removeFormat(quill);
        quill.setSelection(selection.index+length+4);
      }
      // let obj = {
      //     "placeholder": name,
      //     "internal_name": internal_name,
      //     "type": "form",
      //     "id": id
      // }
      // if (!this.formData?.placeholder_metadata) this.formData.placeholder_metadata = []
      // if (!this.formData?.placeholder_metadata?.some(el => el.id === obj.id)) 
      //   this.formData.placeholder_metadata.push(obj)
    },
    formatText(quill, start, length) {
      quill.formatText(start, length, {
        color: 'rgb(13, 105, 213)',
        bold: true,
      });
    },
    removeFormat(quill) {
      quill.format('bold', false);
      quill.format('color', 'black');
    },
    async popupbtn() {
      this.sendoptions = !this.sendoptions;
      await this.saveTemplate();
    },
    placeholderClick() {
    },
    handleSelectedPath(path) {
      this.formData.path = path;
      this.$emit("formDataPath",this.formData.path)
    },
    deleteTemplate() {
      if(this.populateData.mode == 'view'){
         return
      }
      this.$emit("delete", this.populateData?.id);
    },
    insertTable() {
      const betterTable = this.$refs.myQuillEditor.quill.getModule('better-table');
      betterTable.insertTable(3,3)
    },
    async createTemplate() {
      await this.handleSave(this.formData);
      this.formData =  {
        id: "",
        content: "",
        templateName: "",
        description: "",
        templateSubject: "",
        path: "/",
        placeholder_metadata: []
      };
    }
  },

};
